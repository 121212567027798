<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-row :gutter="48">
        <a-col :md="24" :sm="24">
          <span class="table-page-search-submitButtons">
            <a-button style="float: right;margin-bottom: 10px;" @click="goAdd">添加轮播</a-button>
          </span>
        </a-col>
      </a-row>
    </div>
    <a-table :columns="columns" :dataSource="tableData" :loading="tableLoading" rowKey="id">
      <span slot="img_url" slot-scope="text, record">
        <img width="100px" :src="text" :alt="record.title">
      </span>

      <span slot="status" slot-scope="text">
        {{ text | statusFilter }}
      </span>

      <span slot="create_time" slot-scope="text">
        {{ text | dayjs }}
      </span>
      <span slot="action" slot-scope="text, record">
        <a @click="goEdit(record)">编辑</a>
        <a-divider type="vertical" />
        <a-popconfirm v-if="tableData.length" title="确定删除该模板?" @confirm="() => onDelete(record.id)">
          <a href="javascript:;">删除</a>
        </a-popconfirm>
      </span>
    </a-table>

  </a-card>
</template>

<script>
import { rotationList, rotationDelete } from '@/api/rotation'

const columns = [
  {
    title: '图片',
    dataIndex: 'img_url',
    scopedSlots: { customRender: 'img_url' }
  },
  {
    title: '标题',
    dataIndex: 'title'
  },
  {
    title: '描述',
    dataIndex: 'description'
  },
  {
    title: '创建时间',
    dataIndex: 'create_time',
    scopedSlots: { customRender: 'create_time' }
  },
  {
    title: '状态',
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' }
  },
  {
    title: '操作',
    width: '150px',
    scopedSlots: { customRender: 'action' }
  }]

export default {
  name: 'NoticeList',
  data() {
    return {
      description: '公告列表页面',
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      // 高级搜索 展开/关闭
      advanced: false,
      // form
      form: this.$form.createForm(this),
      columns,
      tableData: [],
      tableLoading: false,
      ModalText: '确定删除？'
    }
  },
  filters: {
    statusFilter(status) {
      const statusMap = {
        1: '显示',
        0: '隐藏'
      }
      return statusMap[status]
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.tableLoading = true
      new Promise((resolve, reject) => {
        rotationList().then(response => {
          if (response.status === 'success') {
            resolve(response)
          } else {
            reject(response)
          }
        })
      }).then(res => {
        this.tableData = res.data
      }).catch(error => {
        this.$notification.error({
          message: error.status,
          description: error.message,
          duration: 4
        })
      }).finally(() => {
        this.tableLoading = false
      })
    },
    goAdd() {
      this.$router.push({ 'name': 'RotationEdit' })
    },
    goEdit(record) {
      this.$router.push({ 'name': 'RotationEdit', query: { id: record.id } })
    },
    onDelete(id) {
      new Promise((resolve, reject) => {
        rotationDelete({ 'id': id }).then(response => {
          if (response.status === 'success') {
            resolve(response)
          } else {
            reject(response)
          }
        })
      }).then(res => {
        // 删除成功
        this.$notification.success({
          message: res.status,
          description: res.message
        })
        this.loadData()
      }).catch(error => {
        this.$notification.error({
          message: error.status,
          description: error.message
        })
      })
    }
  }
}
</script>
