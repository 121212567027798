import api from './index'
import { axios } from '@/utils/request'

// 获取轮播图列表
export function rotationList() {
    return axios({
        url: api.RotationList,
        method: 'get',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 获取轮播图详情
export function rotationInfo(parameter) {
    return axios({
        url: api.RotationInfo,
        method: 'get',
        params: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 添加轮播图数据
export function rotationCreate(parameter) {
    return axios({
        url: api.RotationCreate,
        method: 'post',
        data: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 修改轮播图数据
export function rotationUpdate(parameter) {
    return axios({
        url: api.RotationUpdate,
        method: 'post',
        data: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 删除轮播图
export function rotationDelete(parameter) {
    return axios({
        url: api.RotationDelete,
        method: 'post',
        data: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}
